import React, { useRef, useEffect, useState } from "react";
import { FaceDetector, FilesetResolver } from "@mediapipe/tasks-vision";
import { throttle } from 'throttle-debounce'
import { startCameraVideo } from "helpers";
import { drawCanvas } from "helpers/faceTracker";
import { useLogger } from "providers/LoggerProvider";
import { Mark25 } from "assets/fifa-25";

const updateImagePosition = throttle(150, (imgElement, xPos, yPos) => {
  console.log("thottle move image", new Date().getTime());
  imgElement.style.transform = `translate(${xPos}px, ${yPos}px)`;
});

const updateImageSize = throttle(500, (imgElement, markWidth) => {
  imgElement.style.width = `${markWidth.toFixed(0)}px`;
  imgElement.style.height = `${markWidth.toFixed(0)}px`;
});

function animateImage(imgElement, face, canvas, video) {
  const scaledFace = scaleFaceBox(face, video, canvas);
  const originXCenter = 540 / 2;
  const positionXDeviation = face.originX / originXCenter;
  const markWidth = Math.round(scaledFace.width * 1.3 / 5) * 5;
  const xPos =
    Math.round(
      (canvas.clientWidth -
        (scaledFace.originX + scaledFace.width / 2 + markWidth / 2) *
          positionXDeviation) /
        5
    ) * 5;
  const yPos = Math.round((scaledFace.originY - scaledFace.height )/ 5) * 5;

  updateImagePosition(imgElement, xPos, yPos);
  updateImageSize(imgElement, markWidth);
}


function scaleFaceBox(faceBox, videoElement, canvas) {
  const videoWidth = videoElement.videoWidth;
  const videoHeight = videoElement.videoHeight;

  const scaleX = canvas.width / videoWidth;
  const scaleY = canvas.height / videoHeight;

  return {
    originX: faceBox.originX * scaleX,
    originY: faceBox.originY * scaleY,
    width: faceBox.width * scaleX,
    height: faceBox.height * scaleY,
  };
}

const FaceDetectionComponent = ({ element, player = {} }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);  // Reference to the img element
  const { sendLog } = useLogger();
  const [detector, setDetector] = useState(null);
  const { style, mirror = true } = element;

  useEffect(() => {
    // Initialize the MediaPipe FaceDetector
    const initializeFaceDetector = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision/wasm"
      );
      const faceDetector = await FaceDetector.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath:
            "/face_detector/models/blaze_face_short_range.tflite",
            delegate: "GPU",
        },
        runningMode: "VIDEO",
        minDetectionConfidence: 0.4,
      });
      setDetector(faceDetector);
    };

    initializeFaceDetector();
  }, []);

  useEffect(() => {
    let animationFrameId;

    const detectFaces = async () => {
      if (videoRef.current && videoRef.current.readyState >= 2 && detector) {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const imgElement = imageRef.current;

        const results = await detector.detectForVideo(video, performance.now());

        if (results.detections.length > 0) {
          const [detection] = results.detections;
          const face = detection.boundingBox;

            animateImage(imgElement, face, canvas, video);
        }
      }
      animationFrameId = requestAnimationFrame(detectFaces);
    };

    detectFaces();
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [detector, mirror, style]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    const drawFrame = () => {
      if (video && video.readyState === video.HAVE_ENOUGH_DATA) {
        drawCanvas({ canvas, video, mirror, style });
      }
      
      // Schedule the next frame
      requestAnimationFrame(drawFrame);
    };

    // Start drawing frames
    drawFrame();
    
    // Cleanup function to stop drawing when the component unmounts
    return () => {
      cancelAnimationFrame(drawFrame);
    };
  }, [mirror, style]);

  // Start the video stream
  useEffect(() => {
    function init() {
      startCameraVideo(videoRef.current);
    }
    init();
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        style={{
          opacity: 0, // Hide the video element if you only need the canvas
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></video>
      <canvas
        ref={canvasRef}
        width={style.width}
        height={style.height}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1, // Video canvas (background)
        }}
      ></canvas>
      <img
        ref={imageRef}
        src={Mark25}
        style={{
          position: 'absolute',
          transition: 'transform 0.2s ease-out',
          width: '100px',
          height: '100px',
          zIndex: 2,
        }}
        alt="face marker"
      />
    </div>
  );
};

export default FaceDetectionComponent;

