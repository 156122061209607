import { createContext, useContext, useCallback } from 'react';

const LoggerContext = createContext(null);

export function LoggerProvider({ children }) {

  const sendLog = useCallback(async (message) => {
    return window.fetch(
      `https://logger-dev.d3ck.cc/api/logger`,
      {
        method: 'post',
        body: JSON.stringify({ message }),
        headers: {
          'Content-Type': 'application/json'
        },
      }
    ).catch(() => {
      console.log("Failed to log");
    });
  }, []);

  return (
    <LoggerContext.Provider value={{ sendLog }}>
      {children}
    </LoggerContext.Provider>
  );
}

export function useLogger() {
  return useContext(LoggerContext);
}
