import * as faceapi from "face-api.js";

export async function loadFaceApiModels() {
  await faceapi.nets.ssdMobilenetv1.loadFromUri("/face-api/models");
}

export async function getDetection(canvas) {
  return faceapi.detectSingleFace(
    canvas,
    new faceapi.SsdMobilenetv1Options({ minConfidence: 0.5 })
  );
}

function getContentCanvasPosition(mirror, elementWidth, canvasWidth) {
  return {
    dx: mirror
      ? -(canvasWidth / 2 + elementWidth / 2)
      : -(canvasWidth / 2 - elementWidth / 2),
    dy: 0,
  };
}

function getContentCanvasDimensions(elementHeight, videoSize) {
  return {
    width: (videoSize.width * elementHeight) / videoSize.height,
    height: elementHeight,
  };
}

function getCanvasAttributes(mirror, elementSize, videoSize) {
  const { width, height } = getContentCanvasDimensions(
    elementSize.height,
    videoSize
  );
  const { dx, dy } = getContentCanvasPosition(mirror, elementSize.width, width);

  return [dx, dy, width, height];
}

export function getMarkStyles(detectedBox, elementHeight) {
  const detectedBoxBottom = elementHeight - detectedBox.bottom;
  const topSeparation = detectedBox.height * 0.5;
  const markWidth = detectedBox.width / 3;

  return {
    bottom: detectedBoxBottom + detectedBox.height + topSeparation,
    left: detectedBox.left + detectedBox.width / 2 - markWidth / 2,
    width: markWidth,
  };
}

function drawRotatedCanvas(canvas, video, rotation, mirror) {
  const ctx = canvas.getContext("2d");

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  ctx.save();

  ctx.translate(canvas.width / 2, canvas.height / 2);

  if (rotation === 90) {
    ctx.rotate(Math.PI / 2);
  } else if (rotation === -90) {
    ctx.rotate(-Math.PI / 2);
  }

  if (mirror) {
    ctx.scale(-1, 1);
  }

  // Get video dimensions (actual video frame size)
  const videoWidth = video.videoWidth;
  const videoHeight = video.videoHeight;

  // Calculate aspect ratio of the video and canvas
  const videoAspectRatio = videoWidth / videoHeight;
  const canvasAspectRatio = canvas.height / canvas.width;

  // Calculate the scale factor to maintain aspect ratio and fill the canvas
  const scaleFactor = Math.max(canvas.width / videoHeight, canvas.height / videoWidth);

  // Adjust scaling based on the aspect ratio difference
  const adjustedWidth = videoHeight * scaleFactor * (canvasAspectRatio / videoAspectRatio);
  const adjustedHeight = videoWidth * scaleFactor * (videoAspectRatio / canvasAspectRatio);

  ctx.drawImage(
    video,
    -adjustedWidth / 2,
    -adjustedHeight / 2,   
    adjustedWidth,
    adjustedHeight
  );

  ctx.restore();
}

function drawVerticalCanvas(canvas, video, mirror, style) {
  const ctx = canvas.getContext("2d");

  ctx.save();

  const { clientWidth, clientHeight } = video;
  const videoSize = {
    width: clientWidth,
    height: clientHeight,
  };

  if (mirror) {
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.scale(-1, 1);
  }

  ctx.drawImage(video, ...getCanvasAttributes(mirror, style, videoSize));

  ctx.restore();
}

export function drawCanvas({ canvas, video, mirror, rotation }) {
  if(rotation === 90 || rotation === -90) {
    drawRotatedCanvas(canvas, video, rotation, mirror);
  } else {
    drawVerticalCanvas(canvas, video, mirror, canvas);
  }
}
