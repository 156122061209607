import VideoElement from "./VideoElement";
import ImageElement from "./ImageElement";
import TextElement from "./TextElement";
import MemoryCardsElement from "./MemoryCardsElement";
import ButtonElement from "./ButtonElement";
import TimerElement from "./TimerElement";
import CameraInputElement from "./CameraInputElement";
import CameraInputFilterElement from "./CameraInputFilterElement";
import FaceTrackerElement from "./FaceTrackerElement";
import FaceDetectionElement from "./FaceDetectionElement";
import QRCodeElement from "./QRCodeElement";
import InputElement from "./InputElement";

import { isRotated, interpolateOpacity } from "../helpers";
import FaceDetectionComponent from "./face-detection-tasks-vision";
import FaceDetectionLandMarker from "./face-landmarker-tasks-vision";

function getStyle({ currentTime, player, element }) {
  const { timeline, style = {} } = element;
  const { top, bottom, left, right, height, width, zIndex, padding } = style;

  const rotated = isRotated() || (player && player.rotate);

  return {
    top: top,
    bottom: bottom,
    left: left,
    right: right,
    height: height,
    width: width,
    zIndex: zIndex,
    padding: padding,
    opacity: interpolateOpacity(currentTime, timeline),
    ...(rotated && { transform: `translateZ(${zIndex}em)` }),
  };
}

function Element({
  element,
  playing,
  player,
  currentTime,
  setActiveSlide,
  isSlideActive,
  creativeState,
  setCreativeState,
}) {

  function onAction() {
    const { action = {} } = element

    const { type } = action

    switch (type) {
      case 'setSlide':
        const { targetSlide } = action
        return setActiveSlide(targetSlide);
      default:
        return null
    }
  }

  function renderElementType(style) {
    const isVisible = isSlideActive && style.opacity > 0

    switch (element.type) {
      case "text":
        return <TextElement element={element} />;
      case "image":
        return (
          <ImageElement
            element={element}
            playing={playing}
            visible={style.opacity > 0}
            creativeState={creativeState}
            setCreativeState={setCreativeState}
          />
        );
      case "video":
        return (
          <VideoElement
            element={element}
            player={player}
            visible={isSlideActive}
            playing={playing}
            onAction={onAction}
          />
        );
      case "buttonInteractive":
        return (
          <ButtonElement
            element={element}
            setActiveSlide={setActiveSlide}
            creativeState={creativeState}
          />
        );
      case "memoryElement":
        return (
          <MemoryCardsElement
            element={element}
            player={player}
            visible={style.opacity > 0}
            playing={playing}
            setActiveSlide={setActiveSlide}
            isSlideActive={isSlideActive}
          />
        );
      case "timer":
        return (
          <TimerElement
            element={element}
            playing={playing}
            visible={isSlideActive}
          />
        );
      case "cameraInput": {
        return (
          <CameraInputElement
            element={element}
            playing={playing}
            visible={isSlideActive}
            creativeState={creativeState}
            setCreativeState={setCreativeState}
            setActiveSlide={setActiveSlide}
          />
        );
      }
      case "cameraInputFilter": {
        return <CameraInputFilterElement element={element} />;
      }
      case "faceTracker": {
        return <FaceDetectionLandMarker element={element}/>;
      }
      case "faceDetection": {
        return <FaceDetectionElement element={element} onAction={onAction} isVisible={isVisible} />;

      }
      case "qrcode":
        return (
          <QRCodeElement
            element={element}
            player={player}
            creativeState={creativeState}
          />
        );
      case "input":
        return (
          <InputElement
            element={element}
            creativeState={creativeState}
            setCreativeState={setCreativeState}
            playing={playing}
            visible={isSlideActive}
          />
        );
      default:
        return null;
    }
  }

  const style = getStyle({ currentTime, player, element });

  return (
    <div className="element" style={style}>
      {renderElementType(style)}
    </div>
  );
}

export default Element;
