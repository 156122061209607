import React, { useRef, useEffect, useState } from "react";
import { FaceLandmarker, FilesetResolver } from "@mediapipe/tasks-vision";
import { throttle } from "throttle-debounce";
import { startCameraVideo } from "helpers";
import { drawCanvas } from "helpers/faceTracker";
import { useLogger } from "providers/LoggerProvider";
import { Mark25 } from "assets/fifa-25";

// Trottled animation to position the mark
const updateImagePosition = throttle(150, (imgElement, xPos, yPos, markWidth, markRotation) => {
  if (markRotation === 90 || markRotation === -90) {
    imgElement.style.transform = `translate(${xPos - markWidth / 2
      }px, ${yPos}px) rotate(${markRotation}deg)`;
  } else {
    imgElement.style.transform = `translate(${xPos - markWidth / 2
      }px, ${yPos}px)`;
  }
});

function resetImagePosition(imgElement, canvas, markRotation) {
  if (markRotation === 90 || markRotation === -90) {
    imgElement.style.transform = `translate(${canvas.clientWidth / 3}px, ${canvas.clientHeight / 3
      }px) rotate(${markRotation}deg)`;
  } else {
    imgElement.style.transform = `translate(${canvas.clientWidth / 4}px, ${canvas.clientHeight / 4
      }px)`;
  }

  updateImageSize(imgElement, 500);
}

// Trottled animation to resize the mark
const updateImageSize = throttle(100, (imgElement, markWidth) => {
  imgElement.style.width = `${markWidth.toFixed(0)}px`;
  imgElement.style.height = `${markWidth.toFixed(0)}px`;
});

// Euclidean distance between eyes
function calculateFaceWidth(landmarks, canvas) {
  const leftEye = landmarks[33];
  const rightEye = landmarks[263];

  const eyeDistance = Math.sqrt(
    Math.pow(rightEye.x - leftEye.x, 2) + Math.pow(rightEye.y - leftEye.y, 2)
  );

  return eyeDistance * canvas.width;
}

// Euclidean distance between forehead and chin
function calculateFaceHeight(landmarks, canvas) {
  const forehead = landmarks[168];
  const chin = landmarks[10];

  const faceHeight = Math.sqrt(
    Math.pow(chin.x - forehead.x, 2) + Math.pow(chin.y - forehead.y, 2)
  );

  return faceHeight * canvas.height;
}

// Interpolate the difference between the canvas width and the video with to compensate
function interpolateForeheadX(foreheadX) {
  const minX = 0.25;
  const maxX = 0.75;

  const clampedX = Math.max(minX, Math.min(foreheadX, maxX));

  return (clampedX - minX) / (maxX - minX);
}

function adjustLandmarksForRotation(landmarks, rotation, canvas) {
  const adjustedLandmarks = landmarks.map((landmark) => {
    const { x, y } = landmark;

    let newX = x;
    let newY = y;

    if (rotation === 90) {
      // Swap x and y, and adjust for canvas height/width
      newX = y;
      newY = 1 - x; // Invert x because of rotation
    } else if (rotation === -90) {
      // Swap x and y, and adjust for canvas width/height
      newX = 1 - y; // Invert y because of rotation
      newY = x;
    }

    return {
      ...landmark,
      x: newX,
      y: newY,
    };
  });

  return adjustedLandmarks;
}

function animateImage(imgElement, foreheadLandmark, canvas, faceWidth, faceHeight, isMirror, markRotation) {
  const canvasWidth = canvas.clientWidth;
  const canvasHeight = canvas.clientHeight;

  let interpolatedForeheadX = interpolateForeheadX(foreheadLandmark.x);

  if (isMirror) {
    interpolatedForeheadX = 1 - interpolatedForeheadX;
  }

  let xPos = interpolatedForeheadX * canvasWidth;
  let yPos = foreheadLandmark.y * canvasHeight;

  const markWidth = faceWidth * 5;
  if (markRotation === 90 || markRotation === -90) {
    const xOffset = -faceHeight - markWidth / 2;
    xPos += xOffset;
    const yOffset = -faceWidth - markWidth / 2;
    yPos += yOffset;
  } else {
    const yOffset = -faceHeight * 2 - markWidth;
    yPos += yOffset;
  }


  updateImagePosition(imgElement, xPos, yPos, markWidth, markRotation);
  updateImageSize(imgElement, markWidth);
}

const FaceDetectionLandMarker = ({ element, player = {} }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const { sendLog } = useLogger();
  const [detector, setDetector] = useState(null);
  const [isFaceDetected, setIsFaceDetected] = useState(false);
  const { style, rotation, mirror = true, markRotation = false } = element;

  const throttledSendLog = useRef(throttle(6000, (message) => sendLog(message))).current;

  useEffect(() => {
    const initializeFaceDetector = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
      );
      const faceLandmarker = await FaceLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath:
            "https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task",
        },
        outputFaceBlendshapes: true,
        runningMode: "VIDEO",
        numFaces: 1,
        min_face_detection_confidence: 0.4,
        min_face_presence_confidence: 0.4,
        min_tracking_confidence: 0.4,
      });
      setDetector(faceLandmarker);
    };

    initializeFaceDetector();
  }, []);

  useEffect(() => {
    let animationFrameId;

    const detectFaces = async () => {
      if (videoRef.current && videoRef.current.readyState >= 2 && detector) {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const imgElement = imageRef.current;

        const results = await detector.detectForVideo(video, performance.now());

        if (results.faceLandmarks && results.faceLandmarks.length > 0) {
          setIsFaceDetected(true);
          const [landmarks] = results.faceLandmarks;


          // Check rotation and adjust landmarks accordingly
          let adjustedLandmarks = landmarks;
          if (rotation === 90 || rotation === -90) {
            adjustedLandmarks = adjustLandmarksForRotation(landmarks, rotation, canvas);
          }

          const faceWidth = calculateFaceWidth(adjustedLandmarks, canvas);
          const faceHeight = calculateFaceHeight(adjustedLandmarks, canvas);
          const foreheadLandmark = adjustedLandmarks[10]

          animateImage(
            imgElement,
            foreheadLandmark,
            canvas,
            faceWidth,
            faceHeight,
            mirror,
            markRotation
          );
        } else {
          resetImagePosition(imgElement, canvas, markRotation);
          setIsFaceDetected(false);
          throttledSendLog(`Face detected at ${new Date().toISOString()} on player ${player?.bsFrameId}`);
        }
      }
      animationFrameId = requestAnimationFrame(detectFaces);
    };

    detectFaces();
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [detector, mirror, player?.bsFrameId, sendLog, rotation, markRotation]);


  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    const drawFrame = () => {
      if (video && video.readyState === video.HAVE_ENOUGH_DATA) {
        drawCanvas({ canvas, video, mirror, rotation, style });
      }

      requestAnimationFrame(drawFrame);
    };

    drawFrame();

    return () => {
      cancelAnimationFrame(drawFrame);
    };
  }, [mirror, rotation, style]);

  useEffect(() => {
    function init() {
      startCameraVideo(videoRef.current);
    }
    init();
  }, []);


  return (
    <div>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        style={{
          opacity: 0,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></video>
      <canvas
        ref={canvasRef}
        width={style.width}
        height={style.height}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      ></canvas>
      <img
        ref={imageRef}
        src={Mark25}
        style={{
          position: "absolute",
          transition: "all 0.3s ease-out",
          width: "500px",
          height: "500px",
          zIndex: 3,
        }}
        alt="face marker"
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: isFaceDetected
            ? "rgba(0, 0, 0, 0.2)"
            : "rgba(0, 0, 0, 0.6)",
          transition: "all 0.6s ease-in",
          zIndex: 2,
        }}
      />
    </div>
  );
};

export default FaceDetectionLandMarker;
